.reports-container {
    width: 100%;
    display: flex;
    justify-content: space-between
}

.reports-dropdown-container {
    width: 15%;
    display: flex;
    overflow: auto
}

.reports-dropdown {
    flex: 1;
    margin: 10px
}