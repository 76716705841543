.ms-DetailsRow-fields:hover {
    cursor: pointer;
    background-color: #71AFE5;
    transition: 0.3s;
}

.data-grid {
    height: 70%
}

.datagrid-detailslist {
    display: flex;
    overflow: auto;
    height: 90%
}

.datagrid-pageoptions {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    align-content: center
}

.ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0px;
    z-index: 1;
}