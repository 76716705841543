.latency-container {
    width: 100%;
    display: flex;
    justify-content: space-between
}

.latency-filterContainer {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.latency-datetimecombo {
    width: 50%
}

.latency-dropdown-container {
    width: 60%;
    display: flex;
    overflow: auto
}

.latency-dropdown {
    flex: 1;
    margin: 10px
}

.latency-chart {
    width: 50%;
    margin: 1%;
    height: 300px
}

.latency-chartcontainer {
    width: 100%;
    display: flex;
    justify-content: space-between
}
