.einv-search-bar-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.einv-main-search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px
}

.einv-filters-container {
    width: 100%;
    display: flex;
    justify-content: space-between
}

.einv-search-box {
    flex: 4;
    max-width: 60%;
}

.einv-dropdown {
    flex: 1;
    margin: 10px
}

.einv-donut-charts-container {
    width: 60%;
    display: flex;
    overflow: auto
}

.einv-datePicker-container {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.einv-dateTimeCombo {
    width: 50%;
}
.einv-searchDropDown {
    width: 10%
}

.einv-dateTimeNowButton {
    transition: background-color 1s;
    transition: color 0.5s;
    float: left;
    max-width: 20%;
    height: 32px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 4px 4px 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(209, 209, 209);
    text-align: center;
}

.einv-dateTimeNowButton:hover {
    background-color: rgb(0, 120, 212);
    color: white;
}